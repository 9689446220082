import fnsAddDays from 'date-fns/addDays';
import fnsAddMinutes from 'date-fns/addMinutes';
import fnsAddMonths from 'date-fns/addMonths';
import fnsAddWeeks from 'date-fns/addWeeks';
import fnsAddYears from 'date-fns/addYears';
import fnsDifferenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import fnsDifferenceInDays from 'date-fns/differenceInDays';
import fnsDifferenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import fnsDifferenceInMinutes from 'date-fns/differenceInMinutes';
import fnsDifferenceInYears from 'date-fns/differenceInYears';
import fnsEachDayOfInterval from 'date-fns/eachDayOfInterval';
import fnsEachHourOfInterval from 'date-fns/eachHourOfInterval';
import fnsEndOfDay from 'date-fns/endOfDay';
import fnsEndOfMonth from 'date-fns/endOfMonth';
import fnsEndOfWeek from 'date-fns/endOfWeek';
import fnsFormat from 'date-fns/format';
import fnsFormatDistance from 'date-fns/formatDistance';
import fnsFormatDistanceStrict from 'date-fns/formatDistanceStrict';
import fnsGetDate from 'date-fns/getDate';
import fnsGetDay from 'date-fns/getDay';
import fnsGetDayOfYear from 'date-fns/getDayOfYear';
import fnsGetHours from 'date-fns/getHours';
import fnsGetMinutes from 'date-fns/getMinutes';
import fnsGetMonth from 'date-fns/getMonth';
import fnsGetSeconds from 'date-fns/getSeconds';
import fnsGetTime from 'date-fns/getTime';
import fnsGetYear from 'date-fns/getYear';
import fnsHoursToMilliseconds from 'date-fns/hoursToMilliseconds';
import fnsIsAfter from 'date-fns/isAfter';
import fnsIsBefore from 'date-fns/isBefore';
import fnsIsEqual from 'date-fns/isEqual';
import fnsIsSameDay from 'date-fns/isSameDay';
import fnsIsSameMinute from 'date-fns/isSameMinute';
import fnsIsSameMonth from 'date-fns/isSameMonth';
import fnsIsSameWeek from 'date-fns/isSameWeek';
import fnsIsSameYear from 'date-fns/isSameYear';
import fnsIsToday from 'date-fns/isToday';
import fnsIsValid from 'date-fns/isValid';
import fnsIsYesterday from 'date-fns/isYesterday';
import fnsMillisecondsToMinutes from 'date-fns/millisecondsToMinutes';
import fnsMinutesToMilliseconds from 'date-fns/minutesToMilliseconds';
import fnsParse from 'date-fns/parse';
import fnsParseISO from 'date-fns/parseISO';
import fnsSet from 'date-fns/set';
import fnsSetDayOfYear from 'date-fns/setDayOfYear';
import fnsSetHours from 'date-fns/setHours';
import fnsSetMinutes from 'date-fns/setMinutes';
import fnsSetSeconds from 'date-fns/setSeconds';
import fnsStartOfDay from 'date-fns/startOfDay';
import fnsStartOfMinute from 'date-fns/startOfMinute';
import fnsStartOfMonth from 'date-fns/startOfMonth';
import fnsStartOfWeek from 'date-fns/startOfWeek';
import fnsSub from 'date-fns/sub';
import fnsSubDays from 'date-fns/subDays';
import fnsSubMonths from 'date-fns/subMonths';
import fnsSubWeeks from 'date-fns/subWeeks';
import {
  getTimezoneOffset,
  utcToZonedTime as fnsUtcToZonedTime,
  zonedTimeToUtc as fnsZonedTimeToUtc,
} from 'date-fns-tz';
import _isNumber from 'lodash/isNumber';
import _isString from 'lodash/isString';

import {
  DAY_NAME,
  DAYS,
  DAYS_IN_WEEK,
  DEFAULT_TIME_FORMAT,
  FORMAT,
  RANGE_TYPE,
  WORK_DAY_HOUR_END,
  WORK_DAY_HOUR_START,
} from '@@constants/date';
import {
  HOURS_IN_DAY,
  MINUTES_IN_HOUR,
  SECONDS_IN_MINUTE,
  SECONDS_IN_TIMESTAMP,
} from '@@constants/time';

import { DateRangeTypeUnion, TimestampDateRange } from '@@types/ui';

type UnionDate = Date | number;

type TimeFormat = '24H' | '12H';

type TemplateOptions =
  | string
  | {
      template?: string;
      isSimple?: boolean;
      withDay?: boolean;
      withTime?: boolean;
      isFullMonth?: boolean;
      daySeparator?: string;
    };

type TimezoneOptions = {
  branchId?: number;
  timezone?: string;
  isLocal?: boolean;
};

export const getLocale = () => {
  const { LOCALE } = require('interface/System');

  if (!LOCALE || LOCALE === 'en') {
    return require(`date-fns/locale/en-US/index.js`);
  }

  if (LOCALE === 'en_GB') {
    return require(`date-fns/locale/en-GB/index.js`);
  }

  // eslint-disable-next-line import/no-dynamic-require
  return require(`date-fns/locale/${LOCALE}/index.js`);
};

const intlLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getTimezone = ({
  // branchId,
  timezone,
  isLocal,
}: TimezoneOptions = {}) => {
  if (isLocal) {
    return intlLocalTimezone;
  }

  // const { BRANCHES_HASH } = require('interface/Company');
  // const { TIMEZONE } = require('interface/Branch');

  // const branch = branchId ? BRANCHES_HASH[branchId] : null;

  // return timezone || branch?.timezone || TIMEZONE;

  const { TIMEZONE } = require('interface/Company');

  return timezone || TIMEZONE || intlLocalTimezone;
};

export const getTimezoneStringOffset = (
  timezone: string,
  { onlyTime = false }: { onlyTime?: boolean } = {},
): string => {
  const offset = getTimezoneOffset(timezone);

  const offsetByMinutes = Math.abs(offset / 1000 / 60);

  const regex = /[/_]/gi;

  const time = `${offset >= 0 ? '+' : '-'}${Math.trunc(offsetByMinutes / 60)
    .toString()
    .padStart(2, '0')}:${offsetByMinutes % 60 || '00'}`;

  if (onlyTime) {
    return time;
  }

  return `(UTC ${time}) ${timezone
    .replace(regex, ' ')
    // Fuck russian transcription
    .replace('Kiev', 'Kyiv')}`;
};

export const getWeekStartsIndex = (): ExtractValues<typeof DAYS> => {
  const { WEEK_START } = require('interface/Company');

  return DAYS[WEEK_START];
};

export const toDate = (date: UnionDate): Date => {
  if (_isNumber(date)) {
    return new Date(date);
  }

  if (_isString(date)) {
    return fnsParseISO(date);
  }

  return date;
};

export const zonedTimeToUtc = (
  date: UnionDate,
  timezoneOptions: TimezoneOptions = {},
) => {
  const dateObj = toDate(date);

  if (timezoneOptions.isLocal) {
    return fnsGetTime(dateObj);
  }

  const targetTimezone = getTimezone(timezoneOptions);

  return fnsZonedTimeToUtc(dateObj, targetTimezone).getTime();
};

export const utcToZonedTime = (
  date: UnionDate,
  timezoneOptions: TimezoneOptions = {},
) => {
  const dateObj = toDate(date);

  if (timezoneOptions.isLocal) {
    return fnsGetTime(dateObj);
  }

  const targetTimezone = getTimezone(timezoneOptions);

  return fnsUtcToZonedTime(dateObj, targetTimezone).getTime();
};

export const convertTimezones = (
  date: number,
  prevTimezone: string,
  nextTimezone: string,
) => {
  const prevBranchOffset = getTimezoneOffset(prevTimezone, date);
  const nextBranchOffset = getTimezoneOffset(nextTimezone, date);

  const delta = nextBranchOffset - prevBranchOffset;

  return date - delta;
};

export const getTimeTpl = ({
  isPicker = false,
  fixedTimeFormat,
}: { isPicker?: boolean; fixedTimeFormat?: TimeFormat } = {}) => {
  const defaultTimeFormat = DEFAULT_TIME_FORMAT;
  const { TIME_FORMAT = defaultTimeFormat } = require('interface/Company');
  const timeFormat = fixedTimeFormat || TIME_FORMAT;

  const h24 = isPicker ? 'H:i' : 'HH:mm';
  const h12 = isPicker ? 'G:i K' : 'hh:mm a';

  return timeFormat === defaultTimeFormat ? h24 : h12;
};

export const getFormatTpl = (tpl: TemplateOptions = {}) => {
  const { LOCALE } = require('interface/System');

  if (_isString(tpl)) {
    return tpl;
  }

  const {
    template = '',
    isSimple = false,
    withDay = false,
    withTime = false,
    isFullMonth = false,
    daySeparator = '',
  } = tpl;

  const weekdayTpl = ['en', 'es', 'pt', 'tr', 'fr'].includes(LOCALE)
    ? 'eee'
    : 'eeeeee';

  const dayTpl = withDay ? `${daySeparator} ${weekdayTpl}` : '';
  const timeTpl = withTime ? getTimeTpl() : '';
  const timeTplWithSpace = timeTpl && ` ${timeTpl}`;

  if (!template) {
    const {
      DATE_FORMAT = isSimple ? FORMAT.DATE_SIMPLE_MINUTES : FORMAT.DATE_SIMPLE,
    } = require('interface/Company');

    if (!isSimple) {
      const monthTpl = isFullMonth ? 'MMMM' : 'MMM';

      const formattedTpl = DATE_FORMAT.replace(/[/.-]+/g, ' ').replace(
        /M+/,
        monthTpl,
      );

      if (/^MMM\s/.test(formattedTpl)) {
        return `${formattedTpl.replace(/d\s/, 'd, ')}${dayTpl}${timeTplWithSpace}`;
      }

      return `${formattedTpl}${dayTpl}${timeTplWithSpace}`;
    }

    return `${DATE_FORMAT}${dayTpl}${timeTplWithSpace}`;
  }

  return `${template}${dayTpl}${timeTplWithSpace}`;
};

export const getPickerFormatTpl = (tpl: TemplateOptions = {}) => {
  if (_isString(tpl)) {
    return tpl;
  }

  const { template = '', isSimple = false, withTime = false } = tpl;

  if (!template) {
    const {
      DATE_FORMAT = isSimple ? FORMAT.DATE_SIMPLE_MINUTES : FORMAT.DATE_SIMPLE,
    } = require('interface/Company');

    const zeroDayType = /dd/.test(DATE_FORMAT);
    const preTpl = DATE_FORMAT.replace('MM', 'm')
      .replace('M', 'n')
      .replace('yyyy', 'Y')
      .replace('dd', 'd')
      .replace('d', zeroDayType ? 'd' : 'j');

    const timeTpl = withTime ? getTimeTpl({ isPicker: true }) : '';
    const timeTplWithSpace = timeTpl && ` ${timeTpl}`;

    if (!isSimple) {
      return `${preTpl.replace(/[/.-]+/g, ' ')}${timeTplWithSpace}`;
    }

    return `${preTpl}${timeTplWithSpace}`;
  }

  return template;
};

export const parse = (
  date: string,
  tplOpts: TemplateOptions,
  { reference = new Date(), fromISO = false } = {},
) => {
  return fromISO
    ? fnsParseISO(date)
    : fnsParse(date, getFormatTpl(tplOpts), reference, {
        locale: getLocale(),
      });
};

export const format = (
  date: UnionDate,
  tplOpts = {},
  timezoneOptions?: TimezoneOptions,
) => {
  if (!date) {
    return '';
  }

  return fnsFormat(
    utcToZonedTime(toDate(date), timezoneOptions),
    getFormatTpl(tplOpts),
    { locale: getLocale() },
  );
};

export const now = () => new Date().valueOf();

export const eachHourOfInterval = (
  { start, end }: Interval,
  options: {
    step?: number;
  },
) => {
  return fnsEachHourOfInterval(
    {
      start: utcToZonedTime(start),
      end: utcToZonedTime(end),
    },
    options,
  ).map((date) => zonedTimeToUtc(date));
};

export const eachDayOfInterval = (
  { start, end }: Interval,
  options: {
    step?: number;
  },
) => {
  return fnsEachDayOfInterval(
    {
      start: utcToZonedTime(start),
      end: utcToZonedTime(end),
    },
    options,
  ).map((date) => zonedTimeToUtc(date));
};

export const getSeconds = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return fnsGetSeconds(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getMinutes = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return fnsGetMinutes(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getHours = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return fnsGetHours(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getDate = (date: UnionDate, timezoneOptions?: TimezoneOptions) => {
  return fnsGetDate(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getMonth = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return fnsGetMonth(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getYear = (date: UnionDate, timezoneOptions?: TimezoneOptions) => {
  return fnsGetYear(utcToZonedTime(date, timezoneOptions));
};

export const getDay = (date: UnionDate, timezoneOptions?: TimezoneOptions) => {
  return fnsGetDay(utcToZonedTime(date ?? now(), timezoneOptions));
};

export const getDayFrom = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  const day = fnsGetDay(utcToZonedTime(date ?? now(), timezoneOptions));

  return day ? day - 1 : 6;
};

export const isValid = (date: unknown): boolean => {
  if (_isString(date)) {
    return fnsIsValid(fnsParseISO(date));
  }

  return fnsIsValid(date);
};

export const isToday = (date: UnionDate, timezoneOptions?: TimezoneOptions) => {
  return fnsIsToday(utcToZonedTime(date, timezoneOptions));
};

export const isYesterday = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return fnsIsYesterday(utcToZonedTime(date, timezoneOptions));
};

export const isSameMinute = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsSameMinute(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isSameDay = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsSameDay(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isSameWeek = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsSameWeek(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isSameMonth = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsSameMonth(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isSameYear = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsSameYear(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const startOfMinute = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsStartOfMinute(utcToZonedTime(date ?? now(), timezoneOptions)),
    timezoneOptions,
  );
};

export const startOfDay = (
  date?: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsStartOfDay(utcToZonedTime(date ?? now(), timezoneOptions)),
    timezoneOptions,
  );
};

export const endOfDay = (
  date?: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsEndOfDay(utcToZonedTime(date ?? now(), timezoneOptions)),
    timezoneOptions,
  );
};

export const startOfWeek = (
  date?: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsStartOfWeek(utcToZonedTime(date ?? now(), timezoneOptions), {
      weekStartsOn: getWeekStartsIndex(),
    }),
    timezoneOptions,
  );
};

export const endOfWeek = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsEndOfWeek(utcToZonedTime(date ?? now(), timezoneOptions), {
      weekStartsOn: getWeekStartsIndex(),
    }),
    timezoneOptions,
  );
};

export const startOfMonth = (
  date?: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsStartOfMonth(utcToZonedTime(date ?? now(), timezoneOptions)),
    timezoneOptions,
  );
};

export const endOfMonth = (
  date: UnionDate,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsEndOfMonth(utcToZonedTime(date ?? now(), timezoneOptions)),
    timezoneOptions,
  );
};

export const addMinutes = (
  date: UnionDate,
  amount: number,
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    fnsAddMinutes(utcToZonedTime(date, timezoneOptions), amount),
    timezoneOptions,
  );
};

export const addDays = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsAddDays(utcToZonedTime(date), amount));
};

export const addWeeks = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsAddWeeks(utcToZonedTime(date), amount));
};

export const addMonths = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsAddMonths(utcToZonedTime(date), amount));
};

export const addYears = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsAddYears(utcToZonedTime(date), amount));
};

export const sub = (date: UnionDate, duration: Duration) => {
  return zonedTimeToUtc(fnsSub(utcToZonedTime(date), duration));
};

export const subDays = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsSubDays(utcToZonedTime(date), amount));
};

export const subWeeks = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsSubWeeks(utcToZonedTime(date), amount));
};

export const subMonths = (date: UnionDate, amount: number) => {
  return zonedTimeToUtc(fnsSubMonths(utcToZonedTime(date), amount));
};

export const toTimestamp = (
  date: string | UnionDate,
  tplOpts: TemplateOptions,
  timezoneOptions?: TimezoneOptions,
) => {
  if (!date) {
    return '';
  }

  if (!_isString(date)) {
    const localDate = utcToZonedTime(date, timezoneOptions);

    return zonedTimeToUtc(fnsGetTime(localDate), timezoneOptions);
  }

  const localDate = fnsGetTime(parse(date, tplOpts));

  return zonedTimeToUtc(localDate, timezoneOptions);
};

export const set = (date: UnionDate, values: Duration) => {
  return zonedTimeToUtc(fnsSet(utcToZonedTime(date), values));
};

export const setSeconds = (date: UnionDate, seconds: number) => {
  return zonedTimeToUtc(fnsSetSeconds(utcToZonedTime(date), seconds));
};

export const setMinutes = (date: UnionDate, seconds: number) => {
  return zonedTimeToUtc(fnsSetMinutes(utcToZonedTime(date), seconds));
};

export const setHours = (date: UnionDate, seconds: number) => {
  return zonedTimeToUtc(fnsSetHours(utcToZonedTime(date), seconds));
};

export const getDayOfYear = (date: UnionDate) => {
  return fnsGetDayOfYear(utcToZonedTime(date));
};

export const setDayOfYear = (date: UnionDate, dayOfYear: number) => {
  return zonedTimeToUtc(fnsSetDayOfYear(utcToZonedTime(date), dayOfYear));
};

export const isEqual = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsEqual(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isBefore = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsBefore(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const isAfter = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsIsAfter(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const formatDistance = (
  date: UnionDate,
  baseDate: UnionDate,
  options?: {
    includeSeconds?: boolean;
    addSuffix?: boolean;
    locale?: Locale;
  },
) => {
  return fnsFormatDistance(
    utcToZonedTime(date),
    utcToZonedTime(baseDate),
    options,
  );
};

export const formatDistanceStrict = (
  date: UnionDate,
  baseDate: UnionDate,
  options?: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  },
) => {
  return fnsFormatDistanceStrict(
    utcToZonedTime(date),
    utcToZonedTime(baseDate),
    options,
  );
};

export const hoursToMilliseconds = (hours: number) => {
  return fnsHoursToMilliseconds(hours);
};

export const minutesToMilliseconds = (hours: number) => {
  return fnsMinutesToMilliseconds(hours);
};

export const millisecondsToMinutes = (hours: number) => {
  return fnsMillisecondsToMinutes(hours);
};

export const makeNextDay = (date: UnionDate) => {
  return addDays(date, 1);
};

export const stringDateToUniversal = (
  date = '',
  timezoneOptions?: TimezoneOptions,
) => {
  return zonedTimeToUtc(
    new Date(date.split('-').join('/').split('.')[0]),
    timezoneOptions,
  );
};

export const nDaysFromNow = (days = 0, _format?: string) => {
  const date = addDays(now(), days);

  return _format ? format(date, _format) : date;
};

export const nMonthsFromNow = (month = 0, _format?: string) => {
  const date = addMonths(now(), month);

  return _format ? format(date, _format) : date;
};

export const toTime = (_seconds = 0) => {
  const minutes = parseInt(`${_seconds / 60}`, 10);
  const seconds = parseInt(`${_seconds % 60}`, 10);

  return minutes
    ? __('%(minutes)s min. %(seconds)s sec.').format({ minutes, seconds })
    : __('%(seconds)s sec.').format({ seconds });
};

export const lastMonthStart = (date?: UnionDate) => {
  return startOfMonth(subMonths(date ?? now(), 1));
};

export const lastMonthEnd = (date?: UnionDate) => {
  return endOfMonth(subMonths(date ?? now(), 1));
};

export const daysLeft = (dateLeft: UnionDate, dateRight: UnionDate) => {
  return fnsDifferenceInCalendarDays(
    utcToZonedTime(dateLeft),
    utcToZonedTime(dateRight ?? now()),
  );
};

export const yesterdayStart = () => startOfDay(subDays(now(), 1));

export const yesterdayEnd = () => endOfDay(subDays(now(), 1));

export const lastWeekStart = () => {
  return startOfWeek(subWeeks(now(), 1));
};

export const lastWeekEnd = () => {
  return endOfWeek(subWeeks(now(), 1));
};

export const nextWeekStart = () => {
  return startOfWeek(addWeeks(now(), 1));
};

export const nextWeekEnd = () => {
  return endOfWeek(addWeeks(now(), 1));
};

export const nextMonthStart = () => {
  return startOfMonth(addMonths(now(), 1));
};

export const nextMonthEnd = () => {
  return endOfMonth(addMonths(now(), 1));
};

export const tomorrowStart = () => startOfDay(addDays(now(), 1));

export const tomorrowEnd = () => endOfDay(addDays(now(), 1));

export const last30Days = () => subDays(now(), 30);

export const last24Weeks = () => subWeeks(now(), 24);

export const last24Months = () => subMonths(now(), 24);

export const differenceInMilliseconds = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsDifferenceInMilliseconds(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const differenceInMinutes = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsDifferenceInMinutes(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const differenceInDays = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsDifferenceInDays(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const differenceInYears = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsDifferenceInYears(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const differenceInCalendarDays = (
  dateLeft: UnionDate,
  dateRight: UnionDate,
  leftTimezoneOptions?: TimezoneOptions,
  rightTimezoneOptions?: TimezoneOptions,
) => {
  return fnsDifferenceInCalendarDays(
    utcToZonedTime(dateLeft, leftTimezoneOptions),
    utcToZonedTime(dateRight, rightTimezoneOptions),
  );
};

export const getTimeFromTimestamp = (ts = 0) => {
  const seconds = ts / SECONDS_IN_TIMESTAMP;
  const minutes = seconds / SECONDS_IN_MINUTE;
  const hours = minutes / MINUTES_IN_HOUR;
  const days = hours / HOURS_IN_DAY;

  return {
    seconds: Math.floor(Math.abs(seconds)),
    minutes: Math.floor(Math.abs(minutes)),
    hours: Math.floor(Math.abs(hours)),
    days: Math.floor(Math.abs(days)),
  };
};

export const customISO = (
  opts: { t?: string; dateSep?: string; timeSep?: string } = {},
) => {
  const { t = 'T', dateSep = '-', timeSep = ':' } = opts;

  return [
    ['yyyy', 'MM', 'dd'].join(dateSep),
    ['HH', 'mm', 'ss'].join(timeSep),
  ].join(t);
};

export const isCurrentYear = (date: UnionDate) => {
  return getYear(now()) === getYear(date);
};

export const getStringMonthFromDate = (date: UnionDate) => {
  const month = getMonth(date) + 1;

  if (+month < 10) {
    return `0${month}`;
  }

  return month;
};

export const getWeekDaysMap = () => {
  const firstDOW = startOfWeek(now());

  return Array.from(Array(7)).reduce((res, _, i) => {
    const day = addDays(firstDOW, i);
    const dayOfWeek = getDayFrom(day);
    const formattedDay = format(day, 'EEEE');

    return {
      ...res,
      [dayOfWeek]: formattedDay,
    };
  }, {});
};

export const isDateRangeValue = (
  value: string | DateRangeTypeUnion | TimestampDateRange,
): value is TimestampDateRange | DateRangeTypeUnion => {
  if (Array.isArray(value)) {
    return value.every(isValid);
  }

  return Object.values(RANGE_TYPE).includes(value as DateRangeTypeUnion);
};

export {
  DAY_NAME,
  DAYS,
  DAYS_IN_WEEK,
  DEFAULT_TIME_FORMAT,
  FORMAT,
  WORK_DAY_HOUR_END,
  WORK_DAY_HOUR_START,
};
