import * as yup from 'yup';

import { TEXTAREA, TEXTFIELD } from '@@constants/fieldTypes';
import { toLocaleNumber } from '@@helpers/format/number';

const toLocaleNumberError = (value) => {
  return toLocaleNumber(value, { decimalCount: 3 });
};

export const ERROR_MESSAGES = {
  emptyBundle: __('Add at least one item'),
  json: __('JSON data is incorrect!'),
  required: __('The field cannot be empty'),
  requiredSelect: __('Please select one of the options'),
  number: __('Enter a number value'),
  containOnlyNumber: __('Field can contain only digits and decimal separator'),
  minMax: (currency, min, max) =>
    __(
      'Payment amount must be from %(min)s%(currency)s to %(max)s%(currency)s',
    ).format({ min, max, currency }),
  min: (val) =>
    __('The value should be at least %(val)s').format({
      val: toLocaleNumberError(val),
    }),
  minCharacters: (val) =>
    __('Enter at least %(val)s characters', { count: val }).format({ val }),
  max: (val) =>
    __('The value should be less than %(val)s').format({
      val: toLocaleNumberError(val),
    }),
  atLeast: (length) => {
    return __('At least %(min_term_length)s characters').format({
      min_term_length: length,
    });
  },
  minSymbols: (length) => {
    return __(
      'The number of characters must be no less than %(length)s',
    ).format({
      length,
    });
  },
  maxSymbols: (length) => {
    return __('The number of characters must not exceed %(length)s').format({
      length,
    });
  },
  minNumber: (val) => {
    return __('Enter the value no less than %(val)s').format({
      val: toLocaleNumberError(val),
    });
  },
  maxNumber: (val) =>
    __('Enter the value less than %(val)s').format({
      val: toLocaleNumberError(val),
    }),
  range: (i, j) => {
    return __('Enter the value between %(i)s and %(j)s').format({ i, j });
  },
  namedRange: (field, i, j) => {
    return __('The number of %(field)s must be between %(i)s and %(j)s').format(
      { field, i, j },
    );
  },
  noMoreThan: (count) => {
    return __('No more than %(count)s').format({
      count: toLocaleNumberError(count),
    });
  },
  minLoginSymbols: (length) => {
    return __('Login cannot be shorter than %(length)s characters').format({
      length,
    });
  },
  password: __(
    'Password can contain at least 8 characters: Latin letters (A-Z, a-z), special characters and digits (0-9)',
  ),
  currentPassword: __(
    'Password can contain latin letters (A-Z, a-z), special characters and digits (0-9)',
  ),
  'is-valid-parent-category': __("You can't set selected category as parent"),
  'select-warehouse': __('Select warehouse'),
  'select-supplier': __('Select supplier'),
  'select-payer': __('Select payer'),
  'add-serial-number': __('Add serial number'),
  'add-product': __('Add product'),
  'is-valid-discount': __('Discount must be less then price'),
  'is-valid-discount-pct': __('Discount must be less then 100%'),
  'is-serial-number-exists': __('This serial number already exists'),
  isValidAmountOfSerialNumbers: __(
    'The serial numbers quantity must math the product quantity',
  ),
  'is-valid-rate': __('Rate must be less then 100%'),
  'non-negative': __('Enter a non-negative value'),
  integer: __('Enter an integer value'),
  'min-greater-than-max': __(
    'The minimum stock cannot be greater than the maximum',
  ),
  'too-many-files': (count) => {
    return __('You can upload at most %(count)s files at a time').format({
      count,
    });
  },
  'file-too-large': (size) => {
    return __('File size exceeds the maximum limit of {{size}} MB', { size });
  },
  'file-invalid-type': __('Invalid file format'),
  'tax-payer-number': __(
    'TIN must be 10 or 12 in length and consist of numbers',
  ),
  'empty-table': __('Table cannot be empty'),
  email: __('Enter a valid email'),
  'is-required-location': __('Choose a location'),
  'is-valid-category': __('Select category'),
  'template-invalid-characters': __('Template contains invalid characters'),
  'work-schedule-overlap': __('Working hours cannot overlap'),
  'select-client': __('Select client'),
  'is-unique': __('Should be unique'),
  'is-tag-exists': __('This tag already exists'),
  'initial-status': __('You must select initial status'),
  'invalid-ip': __('Invalid IP address format'),
  'incorrect-template': __('Incorrect template'),
  emptyProducts: __('Select at least one product'),
  minSalePrice: __('Enter the sale sum that is greater than zero'),
  positiveNumber: __('Enter a non-negative value'),
  emptyLocations: __('Select location'),
  enterQuantitySerialNumbers: (n) => {
    return __('Enter %(n)s serial numbers').format({ n });
  },
  invalidValue: __('Invalid value'),
  invalidPattern: __('Invalid pattern'),
  invalidToken: __('Invalid token'),
  passwordMustMeetTheRequirements: __('Password must meet the requirements'),
  internationalFormat: __('The phone number should be in international format'),
  noLessThan: (count) =>
    __("Payment amount can't be less than %(val)s").format({ val: count }),
  emptyClientRefund: __('Enter return creation amount'),
  reconnectToTurnFeature: __(
    'Please reconnect the channel or turn off this feature',
  ),
};

export const REG_EXP = {
  EMAIL: /^[+\w.-]+@[a-zA-Z\d.-]+(\.[a-zA-Z\d.-]+)+?$/,
  NUMBER_TEMPLATE: '[^!"#$&\'().,;<=>?@\\[\\]^`|~\\s]+',
  IPV4: '^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',
  PASSWORD: /^[a-zA-Z0-9`~!@#$%^&*()_\-+={}[\]|:;"'<>,.?/\\]+$/,
  SERIAL_NUMBER_PREFIX: '[^!"#$&\'(),;<=>?@\\[\\]^`|~\\s]+',
  IBAN: /^UA\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{4} \d$/,
  UKRAINIAN_CYRILLIC_REGEX: /^[а-яА-ЯіїєґІЇЄҐ'`\s.-]+$/,
  TELEGRAM_BOT_TOKEN: /^(\d+):([A-Za-z0-9-_]+)$/,
};

export const FIELD_MAX_SYMBOLS = {
  [TEXTFIELD]: 256,
  [TEXTAREA]: 16384,
  reg_number: 32,
};

export const RULES = {
  PASSWORD: yup
    .string()
    .matches(REG_EXP.PASSWORD, ERROR_MESSAGES.password)
    .required(ERROR_MESSAGES.password)
    .test(
      'must-meet-requirements',
      ERROR_MESSAGES.passwordMustMeetTheRequirements,
      (value) => {
        const hasLowercase = /[a-z]/.test(value);
        const hasUppercase = /[A-Z]/.test(value);
        const hasDigit = /\d/.test(value);
        const isValidLength = value?.length >= 8;
        return isValidLength && hasLowercase && hasUppercase && hasDigit;
      },
    ),
};
